<template>
  <div class="activityDetails">
    <Header :title="activityDetail.title" />
    <div class="main overflow-y-auto" v-if="!loading">
      <ImgDecypt class="cover" :src="item" v-for="item in activityDetail.pics" :key="item" />
      <div class="sizeBox"></div>
      <div class="joinBtn" @click="join(activityDetail)" v-if="activityDetail.effectiveCode == 10">立即参与</div>
    </div>
    <Loading v-else />
  </div>
</template>
<script>
import Header from '@/components/Header';
import ImgDecypt from '@/components/ImgDecypt';
import Loading from '@/components/Loading';
import { jumpAdv } from '@/utils/getConfig';
import { queryActivityDetails } from '@/api/activity';
import { Toast } from 'vant';

export default {
  name: 'activityDetails',
  components: {
    Header,
    ImgDecypt,
    Loading,
  },
  data() {
    return {
      activityDetail: '',
      id: '',
      loading: true,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getActivity();
  },
  methods: {
    async getActivity() {
      let req = {
        id: this.id,
      };
      let res = await this.$Api(queryActivityDetails, req);
      if (res.code == 200) {
        this.activityDetail = res.data;
        this.loading = false;
      } else {
        Toast('活动详情异常');
      }
    },
    join(item) {
      jumpAdv(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.activityDetails {
  height: 100%;
  .header {
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    height: 44px;
    line-height: 44px;
  }
  .main {
    height: calc(100vh - 44px);
    .cover {
      height: auto !important;
      /deep/ .laz_com {
        background-color: unset;
      }
      /deep/ img {
        width: 100%;
        height: auto !important;
        // object-fit: fill !important;
      }
    }
    .title {
      padding: 0 20px;
      font-size: 16px;
      font-weight: 600;
      color: rgb(65, 65, 65);
      margin-top: 22px;
    }
    .content {
      padding: 0 20px;
      font-size: 14px;
      color: rgb(85, 85, 85);
    }
    .sizeBox {
      height: 90px;
    }
    .joinBtn {
      position: fixed;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 34px;
      width: 271px;
      height: 49px;
      background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      border-radius: 10px;
      color: white;
      text-align: center;
      line-height: 49px;
      font-size: 16px;
    }
  }
}
</style>
