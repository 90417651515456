import axios from '@/utils/request';

/*********************** 活动 ******************/
// 查询活动列表
export function queryActivityList(data) {
  return axios.get(`sge/list`, {
    params: data,
  });
}

// 查询活动详情
export function queryActivityDetails(data) {
  return axios.get(`sge/info`, {
    params: data,
  });
}
